<template>
  <div class="fluid">
    <b-card>
      <div class="row d-flex align-items-center">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-4">
              <label for="" class="label-font">Search:</label>
              <b-form-group class="rounded">
                <b-form-input
                  v-debounce:300ms="getCompanies"
                  v-model="query.search"
                  type="text"
                  placeholder="Search"
                  class="bg-light"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <label for="" class="label-font">Industry :</label>
              <b-form-group class="rounded">
                <v-select
                  @input="getCompanies"
                  v-model="selectedIndustry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="industries"
                  placeholder="Select Industry"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <label for="" class="mx-1 label-font">Status :</label> <br />
              <button @click="changeStatus(0)" class="btn">
                <span class="active-success"></span>
                <span :class="query.status === 0 ? 'has-border' : ''"
                  >Active</span
                >
              </button>
              <button @click="changeStatus(1)" class="btn p-0">
                <span class="active-danger"></span>
                <span :class="query.status === 1 ? 'has-border' : ''"
                  >Inactive</span
                >
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-5 p-0 d-flex align-items-center justify-content-end">
          <a
            href="https://grypas.inflack.xyz/grypas-api/bulk/Company_Data_Sample.xlsx"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
            v-if="$can('create', 'Company')"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Download Sample </span>
              <span>
                <img
                  src="@/assets/images/icons/download-1.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </a>
          <router-link to="/import-company" v-if="$can('create', 'Company')">
            <b-button class="btn mt-1 mt-lg-0 add-btn d-block mr-1">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Import Company </span>
                <span>
                  <img
                    src="@/assets/images/icons/upload-1.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </b-button>
          </router-link>
          <router-link to="/company-create" v-if="$can('create', 'Company')">
            <b-button class="btn mt-1 mt-lg-0 add-btn d-block">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Add Company </span>
                <span>
                  <img
                    src="@/assets/images/icons/add-icon.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </b-button>
          </router-link>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="table-responsive">
        <b-table
          :items="companies"
          :fields="parentFields"
          show-empty
          striped
          hover
          class="bg-white shadow-lg cursor-icon text-left"
        >
          <template #cell(SL)="row">
            <div
              :class="`p-1 ${
                row.item.user_status === 'active'
                  ? 'active-success'
                  : 'active-danger'
              }`"
            >
              <span>
                {{ row.index + from }}
              </span>
            </div>
          </template>
          <template #cell(id)="row">
            <div>
              <span>
                {{ row.item.id }}
              </span>
            </div>
          </template>
          <template #cell(name)="row">
            <!-- <p @click="row.toggleDetails()"> -->
            {{ row.item.name }}
            <img
              v-if="row.detailsShowing"
              src="@/assets/images/icons/arrow.png"
              alt="arrow"
              class="mx-2"
            />
            <!-- </p> -->
          </template>
          <template #cell(address)="row">
            {{ row.item.info ? row.item.info.address : "" }}
          </template>
          <template #cell(location)="row">
            {{ row.item.info.city }}
          </template>
          <template #cell(mobile)="row">
            {{ row.item.phone }}
          </template>
          <template #cell(customer)="row">
            {{ row.item.company_to_customer_count }}
          </template>
          <template #cell(industry)="row">
            {{
              row.item.info.industry && row.item.info.industry.name
                ? row.item.info.industry.name
                : "N/A"
            }}
          </template>

          <template #cell(action)="row">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="logedinUser.roles[0].name === 'superadmin'"
              >
                <template v-if="$can('view', 'Company')" v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <div class="text-center">
                    <b-link
                      v-if="$can('view', 'Company')"
                      :to="`/company/view/${row.item.id}`"
                      class="btn btn-info btn-sm"
                    >
                      View
                    </b-link>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="row.item.status == '0' && $can('suspend', 'Company')"
                    @click.prevent="suspendUser(row.item.id)"
                    class="btn btn-danger btn-sm mx-1"
                  >
                    Suspend
                  </b-button>
                  <b-button
                    v-if="
                      row.item.status == '1' &&
                      $can('withdrawal suspension', 'Company')
                    "
                    @click.prevent="activeUser(row.item.id)"
                    class="btn btn-success btn-sm mx-1"
                  >
                    Withdrawal Suspension
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
              <div class="text-center" v-else>
                <b-link
                  v-if="$can('view', 'Client')"
                  :to="`/customer/view/${row.item.id}`"
                  class="btn btn-info btn-sm"
                >
                  View
                </b-link>
              </div>
            </span>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-if="total > query.per_page"
            class="mt-3 p-1 d-flex justify-content-end bg-white"
            v-model="query.page"
            :total-rows="total"
            :per-page="query.per_page"
            aria-controls="my-table"
            @change="getCompanies($event)"
            pills
            size="lg"
            page-class="info"
          ></b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import { getLogedInUser } from "@/utils/auth";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BDropdownItemButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      homeLink: false,
      companies: [],
      industries: [],
      total: 0,
      from: 0,
      selectedIndustry: [],
      logedinUser: JSON.parse(getLogedInUser()),
      query: {
        name: "",
        page: 1,
        per_page: 20,
        location: "",
        industry: null,
        status: 0,
        search: "",
      },
      parentFields: [
        {
          key: "SL",
          label: "SL",
          sortable: true,
        },
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          label: "name",
          sortable: true,
        },
        {
          key: "address",
          label: "address",
          sortable: true,
        },
        {
          key: "location",
          label: "location",
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "customer",
          label: "Client",
        },
        {
          key: "industry",
          label: "Industry",
        },
        {
          key: "email",
          label: "email",
        },
        {
          key: "address",
          label: "address",
        },
        {
          key: "action",
          label: "action",
        },
      ],
      total: 0,
    };
  },
  created() {
    this.getCompanies();
    this.getIndustries();
  },
  methods: {
    changeStatus(status) {
      this.query.status = status;
      this.getCompanies();
    },
    async getCompanies(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      this.query.industry = this.selectedIndustry
        ? this.selectedIndustry.id
        : null;
      await this.$store
        .dispatch("company/getCompaniesAllInfo", this.query)
        .then(({ data }) => {
          this.companies = data.data;
          this.from = data.from;
          this.total = data.total;
        });
    },
    getIndustries() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("industry/getIndustries", query).then((response) => {
        this.industries = response.data.data;
      });
    },
    suspendUser(item) {
      // statusChange

      let data = {
        id: item,
        status: 1,
      };

      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "danger", "User Suspended");
            this.getCompanies();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Suspending User"
          );
        });
    },
    activeUser(item) {
      // statusChange

      let data = {
        id: item,
        status: 0,
      };
      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getCompanies();
          }
        })
        .catch((error) => {
          // toast(
          //   "Error",
          //   "AlertTriangleIcon",
          //   "danger",
          //   "Error Suspending User"
          // );
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  /* border-radius: 5px; */
  color: white;
}

.has-border {
  border: 1px solid #7190ef;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
  color: #7190ef;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
